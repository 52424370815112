import React from 'react';

import GlyphSocialFbSimple from '@dolstaff/shared/es/icons/GlyphSocialFbSimple';
import GlyphSocialInstagram from '@dolstaff/shared/es/icons/GlyphSocialInstagram';
import GlyphSocialYoutube from '@dolstaff/shared/es/icons/GlyphSocialYoutube';
import Logo from '@dolstaff/shared/es/icons/PureLogoDolFull';
import styled from 'styled-components';

import DolInfo from 'shared/DolInfo';

import { Container } from '../../Blocks';
import MyButton from '../../MyButton';

const Root = styled.div`
  background-color: #242c34;
  padding-top: 2.5em;
  padding-bottom: 1em;
  color: white;
`;

const Main = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5em;
  margin-right: -0.5em;

  .break {
    flex-basis: 100%;
    height: 0;
  }

  .col1,
  .col3,
  .col5 {
    min-width: 100px;
    flex: 4;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  .col0,
  .col2,
  .col4 {
    min-width: 100px;
    flex: 3;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  &.block1 {
    padding-bottom: 4em;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    margin-left: -1em;
    margin-right: -1em;

    .break {
      display: none;
    }

    .col0,
    .col1,
    .col2,
    .col3,
    .col4,
    .col5 {
      margin-left: 1em;
      margin-right: 1em;
      min-width: 100px;
    }

    .col0 {
      flex: initial;
    }
    .col1 {
      flex: 3;
    }
    .col2 {
      flex: 2;
    }
    .col3 {
      flex: 2;
    }
    .col4 {
      flex: 2;
    }
    .col5 {
      flex: 2;
    }
  }
`;
const Divider = styled.div`
  border-top: 1px solid #2d3742;
  margin-top: 2em;
  padding-top: 1em;
  opacity: 0.2;
`;
const Link = styled(MyButton)`
  background-color: transparent;
  border: 0;
  color: white;
  opacity: 0.8;
  font-size: 16px;
  line-height: 1.5;
  padding: 0;
  transition: all 0.1s;
  overflow-wrap: break-word;
  white-space: inherit;
  text-align: left;
  height: auto;
  font-weight: 400;
  margin-bottom: 12px;
  display: block;

  &:hover {
    opacity: 1;
    background-color: transparent;
    color: #d14242;
  }
`;
const SocialButton = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #2d3742;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  text-align: center;
`;

const Footer = () => {
  return (
    <Root>
      <Container>
        <Main>
          <div className="col1">
            <div style={{ marginTop: '1em', marginBottom: '2em' }}>
              <Logo full width={91} height={26} />
            </div>
            <Link
              href="https://g.page/dolenglish?share"
              target="_blank"
              style={{
                marginBottom: '1em',
                opacity: 1
              }}
            >
              <span style={{ maxWidth: '160px', display: 'inline-block' }}>
                Hẻm 458/14 Đường 3/2, P12, Quận 10, TPHCM
              </span>
            </Link>
            <DolInfo>
              {dolInfo => (
                <Link
                  href={`tel:${dolInfo.phone}`}
                  target="_blank"
                  style={{ marginBottom: '2em', opacity: 1 }}
                >
                  {dolInfo.phone}
                </Link>
              )}
            </DolInfo>
            <div>
              <SocialButton
                href="https://www.facebook.com/dolenglish"
                target="_blank"
                style={{ display: 'inline-block', marginRight: '1em' }}
              >
                <GlyphSocialFbSimple size={16} />
              </SocialButton>
              <SocialButton
                href="https://www.youtube.com/channel/UChpTmCcexiro5tbEp4Yhxyg"
                target="_blank"
                style={{ display: 'inline-block', marginRight: '1em' }}
              >
                <GlyphSocialYoutube size={16} />
              </SocialButton>
              <SocialButton
                href="https://www.instagram.com/dolenglish"
                target="_blank"
                style={{ display: 'inline-block' }}
              >
                <GlyphSocialInstagram size={16} />
              </SocialButton>
            </div>
          </div>

          <div className="break" />

          <div
            className="col5"
            style={{ textAlign: 'right', marginTop: '2em' }}
          >
            DOL English là học viện Anh ngữ ra đời với sứ mệnh tạo ra những sản
            phẩm dạy tiếng Anh mang tính đột phá để giúp mọi thế hệ học tiếng
            Anh ở VN dễ dàng nâng cao năng lực Tiếng Anh + Tư Duy, để giúp họ
            vươn tầm cao mới và làm những điều chưa từng.
          </div>
        </Main>
      </Container>

      <Divider />

      <Container>
        <Main>
          <div className="col0">
            <span style={{ fontSize: '14px' }}>
              © 2018 DOL English. All rights reserved.
            </span>
          </div>
        </Main>
      </Container>
    </Root>
  );
};

Footer.propTypes = {};

export default Footer;
