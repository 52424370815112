import React from 'react';

import OutlineMediaIcHeadphonesMic from '@dolstaff/shared/es/icons/OutlineMediaIcHeadphonesMic';
import styled from 'styled-components';

import { Container, Section } from '../shared/Blocks';
import MainLayout from '../shared/MainLayout';
import Title from '../shared/MainLayout/Title';
import Footer from '../shared/Marketing/Footer';
import MySEO from '../shared/MySEO';
import getAssetLink from '../utils/getAssetLink';

const Main = styled.div`
  margin-bottom: 12em;

  .title {
    margin-bottom: 1.5em;
  }
`;

const TrackList = styled.div`
  .trackItem {
    margin-bottom: 1em;
  }
`;

const Track = styled.div`
  .trackTitle {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 0.5em;
  }

  audio {
    outline: none;
  }
`;

const trackList = [{ handle: 'SCqldgauQ5OFijgUos3C' }];

class IndexPage extends React.PureComponent {
  render() {
    return (
      <MainLayout hideSideCTA noFooter>
        <MySEO title="Listening 01" noSEO />

        <Section>
          <Container>
            <Main>
              <Title className="title">
                <OutlineMediaIcHeadphonesMic size={40} /> Listening 01
              </Title>

              <TrackList>
                {trackList.map((i, idx) => (
                  <div className="trackItem" key={`track-${idx}`}>
                    <Track>
                      <audio controls>
                        <source
                          src={getAssetLink({ item: i })}
                          type="audio/mpeg"
                        />
                        <track kind="captions" />
                        Your browser does not support the audio element.
                      </audio>
                    </Track>
                  </div>
                ))}
              </TrackList>
            </Main>
          </Container>
        </Section>

        <Footer />
      </MainLayout>
    );
  }
}

IndexPage.propTypes = {};

export default IndexPage;
